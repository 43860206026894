<template>
    <div class="bigbox">
        <div>
            <van-nav-bar title="等待应答" left-arrow @click-left="onClickLeft"/>
        </div>
        <div>
            <div style="margin-top:20px" class="locationInfo">
                <div class="statrt">
                    <img style="width:10px;height:10px;margin-top:15px" src="../../assets/images/startAddress.png" alt/>
                    <input
                            type="text"
                            v-model="startAddress"
                            placeholder="请输入上车地址"
                            style="margin-left:10px;border:0;width:250px"
                            class="input"
                    />
                </div>
                <van-divider/>
                <div class="end">
                    <img style="width:10px;height:10px;margin-top:15px" src="../../assets/images/endAddress.png" alt/>
                    <input
                            v-model="endAddress"
                            class="input"
                            style="margin-left:10px;border:0;width:250px"
                            type="text"
                            placeholder="请输入下车地址"
                    />
                </div>
            </div>
            <div style="margin-top:20px" class="locationInfo">
                <div style="text-align: center;padding-top:30px;color:#333">{{this.nexttitle}}</div>
                <van-divider/>
                <div style="text-align: center;padding-top:10px" @click="cancel">
                    <img style="width:10px;height:10px" src="../../assets/images/cancel.png" alt/> 取消订单
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {no_api, orderdetail_api} from '../../http/api/price'

    export default {
        name: 'TaxiH5Car',

        data() {
            return {
                startip: null,
                endIp: null,
                startAddress: null,
               // startAddress1: '四川省成都市武侯区天府芙蓉园簇马路一段69号四川省成都市武侯区天府芙蓉园簇马路一段69号四川省成都市武侯区天府芙蓉园簇马路一段69号',
                endAddress: null,
                //endAddress1: '四川省成都市武侯区天府芙蓉园簇马路一段69号四川省成都市武侯区天府芙蓉园簇马路一段69号四川省成都市武侯区天府芙蓉园簇马路一段69号',
                nexttitle: '正在为您寻找车辆，感谢耐心等待',
                timer: '',
                token: '',
                number:0,

            }
        },

        mounted() {

            this.startip = sessionStorage.getItem('startIp').split(',')
            this.endIp = sessionStorage.getItem('endIp').split(',')

            this.startAddress = sessionStorage.getItem('startAddress')
            this.endAddress = sessionStorage.getItem('endAddress')
            this.token = JSON.parse(sessionStorage.getItem('token')).token
            console.log('token',this.token)
            this.details();
            this.timerSetInterval();
        },

        methods: {

            //定时器：5秒
            timerSetInterval() {
                //循环定时器
                //定时器循环调用的方法：getAllValue
                this.timer = setInterval(this.details, 10000);
            },
            //销毁定时器
            beforeDestroy() {
                clearInterval(this.timer);
                this.timer = '';
            },
            async details() {
                let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))

                let res = await orderdetail_api({
                    pathOrderId: nowOrder.backNum,
                    carSource: nowOrder.carType,
                    token: this.token, //登录标识token
                    timestamp: new Date().getTime()
                })

                //等待司机
                if (res.data == null) {
                    console.log(res)
                    console.log('进入了取消订单中');
                    //移除司机位置
                    sessionStorage.removeItem('driverAddress')
                    this.content = '订单已经完成';
                    this.beforeDestroy();
                    this.$router.push({
                        path: '/over',
                        query: {title: '订单超时，请稍后再试'}
                    })
                } else {
                    //等待司机
                    if (res.data.order.status == 2) {
                        console.log('进入了等待司机');
                        //将司机位置存入 session
                        sessionStorage.setItem('driverAddress', res.data.driver.currentDlng + "," + res.data.driver.currentDlat)
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/pick',
                        })
                    }
                    //司机到达
                    if (res.data.order.status == 3) {
                        console.log('进入了司机到达');
                        //将司机位置存入 session
                        sessionStorage.setItem('driverAddress', res.data.driver.currentDlng + "," + res.data.driver.currentDlat)
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/pick',
                        })
                    }
                    //行程中
                    if (res.data.order.status == 4) {
                        console.log('进入了行程中');
                        //将司机位置存入 session
                        sessionStorage.setItem('driverAddress', res.data.driver.currentDlng + "," + res.data.driver.currentDlat)
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/pick',
                        })
                    }
                    //订单支付
                    if (res.data.order.status == 6) {
                        console.log('进入了行程中');
                        //移除司机位置
                        sessionStorage.removeItem('driverAddress')
                        sessionStorage.setItem("nowOrder", JSON.stringify(res.data.order))
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/payment',
                        })
                    }
                    //取消订单
                    if (res.data.order.status == 7) {
                        console.log('进入了取消订单中');
                        //移除司机位置
                        sessionStorage.removeItem('driverAddress')
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/over',
                            query: {title: '订单超时，请稍后再试'}
                        })
                    }
                }
                this.number = this.number+1;
                console.log('当前叫车次数',this.number)
                if(this.number==-1){
                    this.beforeDestroy();
                    //取消订单  --提示当前等待人数过多 请稍后再试
                    console.log('当前等待人数过多 请稍后再试')


                    let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
                    let res = await no_api({
                        pathOrderId: nowOrder.backNum, //易启返回订单号
                        cancelReason: '不想坐了', //取消原因
                        carSource: nowOrder.carType, //车型对应id
                        token: this.token, //登录标识token
                    })
                    console.log(res)
                    if (res.code == 200) {
                        sessionStorage.removeItem('endAddress')
                        sessionStorage.removeItem('endIp')
                        sessionStorage.removeItem('nowOrder')
                        sessionStorage.removeItem('startAddress')
                        sessionStorage.removeItem('startIp')
                        this.beforeDestroy();
                        this.$router.push({
                            path: '/home',
                        })
                    }

                }

            },

            async cancel() {
                this.beforeDestroy();
                    this.$router.push({
                        path: '/cancel',
                    })
                // let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
                // let res = await no_api({
                //     pathOrderId: nowOrder.backNum, //易启返回订单号
                //     cancelReason: '不想坐了', //取消原因
                //     carSource: nowOrder.carType, //车型对应id
                //     token: this.token, //登录标识token
                // })
                // console.log(res)
                // if (res.code == 200) {
                //     // sessionStorage.removeItem('endAddress')
                //     // sessionStorage.removeItem('endIp')
                //     // sessionStorage.removeItem('nowOrder')
                //     // sessionStorage.removeItem('startAddress')
                //     // sessionStorage.removeItem('startIp')
                //     this.beforeDestroy();
                //     this.$router.push({
                //         path: '/cancel',
                //     })
                // }
            },
            onClickLeft() {
                window.history.back(-1)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .bigbox {
        background: #f8f8f8;
        width: 100%;
        height: 100vh;
        .locationInfo {
            height: 120px;
            border-radius: 10px;
            background-color: #ffffff;
            margin: auto;
            width: 90%;
            .statrt {
                height: 40px;
                 line-height: 0px;
                margin-left: 20px;
                display: flex;

                 .input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
     // margin-left: 10px;
      border: 0;
      width: 80%;
      background-color: transparent;
      margin-top: 5px;
    }
                
            }
            .end {
                height: 40px;
                //line-height: 40px;
                margin-left: 20px;
                display: flex;
                 .input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
     // margin-left: 10px;
      border: 0;
      width: 80%;
      background-color: transparent;
    }
            }
        }
    }
</style>