<template>
  <div>
    <div class="bar">
    <!-- <img src="../assets/images/left.png" alt="" class="orderImg"> -->
   <!-- <van-icon name="arrow-left" /> -->
   <!-- <span class="title">个人中心</span> -->
      <van-nav-bar
  title="登录"
  
  
  left-arrow
  bind:click-left="onClickLeft"
  bind:click-right="onClickRight"
  @click-left="$router.back()"
/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxiH5Nav',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.bar {
  // position: fixed;
  // top: 0;
  // z-index: 99;
  // width: calc(100vw - 20px);
  // height: 40px;
  // background-color: #eee;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 0 10px;
  // display: flex;
  width: 100%;
  box-sizing: border-box;
  .title{
    text-align: center;
  }
}
</style>