<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="人才出行"
                   left-arrow
                   @click-left="onClickLeft" />
    </div>
    <div id="container"></div>
    <div class="topbox">
      <div>{{content}}</div>
    </div>
    <div class="foot">
      <div class="top">
        <div style="line-height: 20px;">
          <div style="font-size:18px;font-weight:bold">{{this.driver.vehicleNo}}</div>
          <div>{{this.driver.vehicleModel}}.{{this.driver.vehicleColor}}</div>
          <div>{{this.driver.driverName}}</div>
        </div>
        <img style="width:40%;height:60px"
             src="../../assets/images/car.png"
             alt />
      </div>
      <van-divider />
      <div class="bootm"
           v-show="isshow">
        <div>
          <a :href="'tel:'+this.driver.driverPhoneVirtual"
             ref="telPhone"
             style="display:none;"></a>
          <div class="app_fixed_phone"
               @click="clickPhone">
            <van-icon name="phone-circle" />
            <span>打电话</span>
          </div>
        </div>
        <div @click="cancel">
          <van-icon name="close" />取消订单
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { orderdetail_api } from '../../http/api/price'

export default {
  name: 'TaxiH5Pick',
  data () {
    return {
      content: '',
      driver: {},
      isshow: true,
    }
  },

  mounted () {
    this.initMap()
    this.mark()
    this.timerSetInterval()
    this.details()
  },

  methods: {
    //打电话
    clickPhone () {
      //Toast('确定拨打电话：010-xxxxxx吗？');
      Dialog.confirm({
        title: '提示',
        message: '确定拨打电话：' + this.driver.driverPhoneVirtual,
        confirmButtonColor: '#09BB07',
      })
        .then(() => {
          // on confirm
          this.$refs.telPhone.click()
        })
        .catch(() => {
          // on cancel
        })
    },
    //取消
    async cancel () {
      this.$router.push({
        path: '/cancel',
      })
    },

    //定时器：5秒
    timerSetInterval () {
      //循环定时器
      //定时器循环调用的方法：getAllValue
      this.timer = setInterval(this.details, 10000)
    },
    //销毁定时器
    beforeDestroy () {
      clearInterval(this.timer)
      this.timer = ''
    },
    async details () {
      let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      if (nowOrder == null) {
        this.beforeDestroy()
        this.$router.push({
          path: '/index',
        })
      }
      let res = await orderdetail_api({
        pathOrderId: nowOrder.backNum,
        carSource: nowOrder.carType,
        token: JSON.parse(sessionStorage.getItem('token')).token, //登录标识token
      })
      console.log(res.data.order)
      this.driver = res.data.driver
      //等待司机
      if (res.data.order.status == 2) {
        console.log('进入了等待司机')
        //将司机位置存入 session
        sessionStorage.setItem(
          'driverAddress',
          res.data.driver.currentDlng + ',' + res.data.driver.currentDlat
        )
        this.content =
          '司机正在赶来，预计还需' + res.data.driver.estimatedTime + '分钟'
        this.mark()
      }
      //司机到达
      if (res.data.order.status == 3) {
        console.log('进入了司机到达')
        //将司机位置存入 session
        sessionStorage.setItem(
          'driverAddress',
          res.data.driver.currentDlng + ',' + res.data.driver.currentDlat
        )
        this.content = '司机已经到达，请尽快上车'
        this.mark()
      }
      //行程中
      if (res.data.order.status == 4) {
        console.log('进入了行程中')
        //将司机位置存入 session
        sessionStorage.setItem(
          'driverAddress',
          res.data.driver.currentDlng + ',' + res.data.driver.currentDlat
        )
        this.content = '行驶中，请乘客系好安全带'
        this.mark()
        this.isshow = false
      }
      //订单支付
      if (res.data.order.status == 6) {
        console.log('进入了行程中')
        //移除司机位置
        sessionStorage.removeItem('driverAddress')
        this.content = '订单已经完成，正在跳转支付'
        sessionStorage.setItem('nowOrder', JSON.stringify(res.data.order))
        this.beforeDestroy()
        this.$router.push({
          path: '/payment',
        })
      }
      //取消订单
      if (res.data.order.status == 7) {
        console.log('进入了取消订单中')
        //移除司机位置
        sessionStorage.removeItem('driverAddress')
        this.beforeDestroy()
        this.content = '订单已经完成，正在跳转支付'
        sessionStorage.setItem('nowOrder', JSON.stringify(res.data.order))
        this.beforeDestroy()
        this.$router.push({
          path: '/over',
          query: { title: '司机取消订单' },
        })
      }
    },
    onClickLeft () {
      this.$router.push({
        path: '/index',
      })
    },
    initMap () {
      let start = sessionStorage.getItem('startIp').split(',')
      let end = sessionStorage.getItem('endIp').split(',')
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 15,
        center: sessionStorage.getItem('startIp').split(','),
      })

      //构造路线导航类
      let driving = new AMap.Driving({
        map: this.map,
        // panel: "panel"
      })

      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        new AMap.LngLat(start[0], start[1]),
        new AMap.LngLat(end[0], end[1]),
        function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          console.log(result)
          if (status === 'complete') {
            console.log('获取驾车数据成功')
          } else {
            console.log('获取驾车数据失败')
          }
        }
      )
    },
    mark () {
      if (sessionStorage.getItem('driverAddress') != null) {
        let driverAddress = sessionStorage.getItem('driverAddress')
        let driver = driverAddress.split(',')
        //添加前先移除
        // 获取已经添加的marker
        var markerold = this.map.getAllOverlays('marker')
        // 单独移除点标记
        this.map.remove(markerold)
        //创建 AMap.Icon 实例：
        var icon = new AMap.Icon({
          size: new AMap.Size(140, 150), // 图标尺寸
          // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
          //image: '//webapi.amap.com/images/car.png',
          image:
            'https://mihuatang.xyz/images/2023/05/04/9e192227-a5ea-45c6-bbad-c3641d1f035a.png',
        })
        // 创建图片图层
        // var icon = new AMap.ImageLayer({
        //   bounds: new AMap.Bounds(),
        //   url: '图片url', // 图片 Url
        //   zIndex: 2,
        //   zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
        // })
        // 创建司机位置标记
        var marker = new AMap.Marker({
          position: new AMap.LngLat(driver[0], driver[1]),
          //position: new AMap.LngLat(104.06743,30.59051),
          offset: new AMap.Pixel(-10, -10),
          icon: icon, // 添加 Icon 图标 URL
          //content: '司机位置',
          title: '司机位置',
        })
        this.map.add(marker)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  position: relative;

  #container {
    width: 100%;
    height: 95%;
    //background: #eee;
  }
  .topbox {
    position: absolute;
    width: 90%;
    height: 40px;
    background: #fff;
    top: 80px;
    margin: 0 5%;
    line-height: 40px;
    border-radius: 15px;
    text-align: center;
    font-size: 14px;
  }
  .foot {
    position: absolute;
    width: 90%;
    height: 130px;
    background: #fff;
    bottom: 10px;
    margin: 0 5%;
    border-radius: 15px;
    font-size: 12px;
    .top {
      display: flex;
      justify-content: space-between;
      margin: 5px 15px;
      height: 60px;
      align-items: center;
    }
    .bootm {
      //margin-left: 40%;
      display: flex;
      justify-content: space-between;
      margin: 0 20%;
    }
  }
}
</style>