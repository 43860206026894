<template>
    <div class="bigbox">
        <div>
            <TopBar/>
        </div>
        <div id="container"></div>
        <div id="panel"></div>
        <div class="cancel" @click="cancel">取消</div>
        <div class="box">
            <div class="tabbox">
                <van-tabs v-model="active" @click="checkCar">
                    <van-tab title="曹操"></van-tab>
                    <van-tab title="首汽"></van-tab>
                    <van-tab title="红旗"></van-tab>
                    <van-tab title="T3"></van-tab>
                    <van-tab title="神州"></van-tab>
                </van-tabs>
            </div>
            <div class="infoBox">
                <div class="address">
                    <img class="imgbox" src="../../assets/images/car.png" alt="车车"/>
                    <div style="line-height:25px;">
                        <div>
                            预估价格
                            <span style="color:red">￥{{this.estimatePrice}}</span>
                        </div>

                        <div style="color:#999">
                            预计
                            <span style="color:#000">{{this.estimatedTime}}</span>分钟到达
                        </div>
                        <div style="color:#999">
                            距离目的地
                            <span style="color:#000">{{this.distance}}</span>公里
                        </div>
                    </div>
                </div>
                <button v-if="this.estimatePrice!='--'" class="confirmBtn" @click="gohome">确认</button>
                <button v-else class="confirmBtn2" disabled>确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    //import { Notify } from 'vant';
    import TopBar from '@/components/TopBar.vue'
    import {price_api, order_api} from '../../http/api/price'

    export default {
        name: 'TaxiH5Price',
        components: {
            TopBar,
        },
        data() {
            return {
                map: null,
                active: 0,
                estimatePrice: '',
                distance: '',
                estimatedTime: '',
                CarSource: '4',
                carConstance: null,
                result: {},
                startip: null,
                endIp: null,
                startAddress: null,
                endAddress: null,
                value: '',
                token: '',
                departureTime:'',
                phone:'',
                serviceType:'',
                cityID:''
            }
        },

        mounted() {
            this.token = JSON.parse(sessionStorage.getItem('token')).token
            this.startip = sessionStorage.getItem('startIp').split(',')
            this.endIp = sessionStorage.getItem('endIp').split(',')

            this.startAddress = sessionStorage.getItem('startAddress')
            this.endAddress = sessionStorage.getItem('endAddress')
            this.serviceType = sessionStorage.getItem('serviceType')
            this.cityID = sessionStorage.getItem('cityID')
            if(this.serviceType!=null&&this.serviceType==0){
                this.serviceType=1
            }else{
                this.serviceType=2
            }

            var departureTime = sessionStorage.getItem('departureTime')
            if(departureTime!=null){
                this.departureTime = departureTime;
            }
            this.phone = JSON.parse(sessionStorage.getItem('token')).phone



            var carConstance = new Map()
            carConstance.set('曹操', 4)
            carConstance.set('神州', 3)
            carConstance.set('红旗', 7)
            carConstance.set('T3', 11)
            carConstance.set('首汽', 5)
            this.carConstance = carConstance
            this.initMap()
            this.checkCar(0, '曹操')
        },

        methods: {
            //选择车型
            checkCar(name, title) {
                this.CarSource = this.carConstance.get(title)
                console.log(this.CarSource)
                this.price()
            },
            async price() {
                let res = await price_api({
                    Flat: this.startip[1],
                    Flng: this.startip[0],
                    Tlat: this.endIp[1],
                    Tlng: this.endIp[0],
                    CityId:this.cityID,
                    RequireLevel: '1',
                    ServiceType: this.serviceType,
                    DepartureTime: this.departureTime,
                    PassengerPhone: this.phone,
                    CarSource: this.CarSource,
                    FromAddress: this.startAddress,
                    ToAddress: this.endAddress,
                })
                console.log('res', res)
                if (res.code == 200) {
                    this.result = res.data[0]

                    this.estimatePrice = res.data[0].estimatePrice
                    this.distance = res.data[0].distance
                    this.estimatedTime = res.data[0].estimatedTime
                } else {
                    this.estimatePrice = '--'
                    this.distance = '--'
                    this.estimatedTime = '--'
                    //Notify('该地区不支持该打车平台，请切换其他平台');
                }
            },
            initMap() {
                let start = sessionStorage.getItem('startIp').split(',')
                let end = sessionStorage.getItem('endIp').split(',')
                this.map = new AMap.Map('container', {
                    resizeEnable: true,
                    zoom: 18,
                    center: sessionStorage.getItem('startIp').split(','),
                })

                //构造路线导航类
                let driving = new AMap.Driving({
                    map: this.map,
                    // panel: "panel"
                })

                // 根据起终点经纬度规划驾车导航路线
                driving.search(
                    new AMap.LngLat(start[0], start[1]),
                    new AMap.LngLat(end[0], end[1]),
                    function (status, result) {
                        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
                        console.log(result)
                        if (status === 'complete') {
                            console.log('获取驾车数据成功')
                        } else {
                            console.log('获取驾车数据失败')
                        }
                    }
                )
            },
            //确定
            async gohome() {
                let res = await order_api({
                    //  Flat: this.startip[1],
                    // Flng: this.startip[0],
                    // Tlat: this.endIp[1],
                    // Tlng: this.endIp[0],
                    serviceType:this.serviceType, //订单类型  1.实时单 2.预约订单 3.接机单 4.送机单 5.日租 6.半日租
                    cityId: this.cityID, //城市id
                    flat: this.startip[1], //出发地纬度
                    flng: this.startip[0], //出发地经度
                    startName: this.startAddress, //出发地名称
                    startAddress: this.startAddress, //出发地详细地址
                    tlat: this.endIp[1], //目的地纬度
                    tlng: this.endIp[0], //目的地经度
                    endName: this.endAddress, //目的地名称
                    endAddress: this.endAddress, //目的地详细地址
                    departureTime:this.departureTime, //出发时间(yyyy-MM-dd HH:mm:ss)
                    requireLevel: '1', //车型
                    dynamicCode: this.result.dynamicCode, //预估价动态码(调用预估价接口时，如果接口返回了DynamicCode则此处要传入，否则可以传空""或不传)
                    estimatePrice: this.result.estimatePrice, //预估价
                    carSource: this.CarSource, //平台  例如:曹操之类的对应id
                    fltTakeoffTime: '', //航班起飞时间，如果是接机订单，则是必须的(yyyy-MM-dd HH:mm:ss)
                    flightDelayTime: '', //航班到达后多少分钟出发，只能取10-90之间，10的整数倍数字 ，与AirCode成对出现，要么都传，要么都不传，如果是接机订单，则是必须的
                    departureAirPortCode: '', //出发机场三字码，如果是接机订单，则是必须的
                    arriveAirPortCode: '', //接机机场三字码，如果是接机订单，则是必须的
                    token: this.token, //用户登录标识token
                })
                console.log(res)
                if (res.code == 200) {
                    sessionStorage.setItem("nowOrder", JSON.stringify(res.data))
                    this.$router.push({
                        path: '/car'
                    })
                } else {
                    // this.$notify({
                    //     message: res.message,
                    //     background: '#00BFFF',
                    //     duration: 1000
                    // })
                }
            },
            //取消
            cancel() {
                this.$router.push({
                    path: '/home',
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .bigbox {
        position: relative;
        width: 100%;
        height: 100vh;

        #container {
            width: 100%;
            height: 100%;
        }

        .cancel {
            position: absolute;
            width: 70px;
            height: 40px;
            background: #fff;
            bottom: 200px;
            left: 20px;
            border-radius: 15px;
            line-height: 40px;
            text-align: center;
        }
        .box {
            position: absolute;
            bottom: 3%;
            width: 100%;
            height: 200px;
        }
        .tabbox {
            z-index: 999999;
            width: 90%;
            position: absolute;
            bottom: 150px;
            padding: 0px 0;
            height: 20px;
            background: #ffffff;
            margin: 0 5%;
            border-radius: 15px;
        }
        .infoBox {
            z-index: 999999;
            width: 90%;
            position: absolute;
            bottom: 0px;
            padding: 0px 0;
            height: 120px;
            background: #ffffff;
            margin: 0 5%;
            border-radius: 15px;
            .address {
                display: flex;
                height: 80px;
                justify-content: space-between;
                margin: 0 25px;
                .imgbox{
                    width:50%
                }
            }

            .confirmBtn {
                width: 90%;
                height: 40px;
                background: #eb4541;
                line-height: 70 rpx;
                color: #ffffff;
                text-align: center;
                border-radius: 15px;
                margin: 0 5%;
                line-height: 40px;
                font-size: 14px;
                border: 0;
            }
            .confirmBtn2 {
                width: 90%;
                height: 40px;
                background: #eee;
                line-height: 70 rpx;
                color: #666;
                text-align: center;
                border-radius: 15px;
                margin: 0 5%;
                line-height: 40px;
                font-size: 14px;
                border: 0;
            }
        }
    }
</style>