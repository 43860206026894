<template>
    <div class="bigbox">
        <div>
            <van-nav-bar title="支付订单" left-arrow @click-left="$router.back()"></van-nav-bar>
        </div>
        <div></div>
        <button class="btn" @click="pay">付款</button>
    </div>
</template>

<script>
    import {wechat_api} from '../../http/api/pay'
    export default {
        name: 'paytest',
        components: {},
        data() {
            return {
                showList: false,
                chosenCoupon: -1,
                coupons: [],
                disabledCoupons: [],
                totalFee: '',
                nowOrder: {},
                couponId: 0,
                startAddress: '',
                endAddress: '',
                integral: 10060,
                mileage: 100,
                token:''
            }
        },

        mounted() {
            this.token = JSON.parse(sessionStorage.getItem('token')).token
        },

        methods: {
            pay(){
                this.wechatPayMethord();
                console.log('进入了')


            },
            async wechatPayMethord() {
                //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
                let res = await wechat_api({
                    orderNum:"202110211642249039",
                    cash:"1",
                    token:this.token

                })

                if (res.code == 200) {
                    console.log(res)
                    location.href = res.data.url
                    
                }
            }



        },
    //      beforeDestroy() {
    //   clearInterval(this.timer);
    // }
    }
</script>

<style lang="scss" scoped>
    .bigbox {
        background: #f8f8f8;
        width: 100%;
        height: 100vh;
        .topbox {
            margin-top: 10px;
            height: 180px;
            background: #fff;
            width: 95%;
            margin-left: 2.5%;
            text-align: center;
            border-radius: 15px;
        }
        .address {
            width: 95%;
            height: 100px;
            margin-top: 10px;
            background: #fff;
            margin-left: 2.5%;
            border-radius: 15px;
            .input{
                 white-space: nowrap;
                overflow: hidden;
                text-overflow:ellipsis;
            }
            .yq {
                width: 20px;
                height: 20px;
                background: #62ce62;
                border-radius: 50%;
                color: #62ce62;
            }
            .yellow {
                width: 20px;
                height: 20px;
                background: #ffb540;
                border-radius: 50%;
                color: #ffb540;
            }
        }
        .coupons {
            width: 95%;
            height: 40px;
            border-radius: 15px;
            margin: 10px 2.5%;
        }
        .btn {
            width: 95%;
            height: 40px;
            margin: 20% 2.5%;
            background: #eb4541;
            border: 0;
            color: #fff;
            border-radius: 15px;
        }
    }
</style>