<!-- 这是打车H5的一个DEMO架子 已配置了移动端单位适配 会自动把px转为rem 写样式的时候用px就好
    地图组件使用的是高德的JSAPI
    只写了一点点😅
 -->
<template>
  <div class="home">
    <TopBar class="topbar"/>
    <div id="container"></div>
    <div class="infoBox">
      <van-tabs v-model="active" class="tabs">
        <van-tab title="现在">
          <div class="locationInfo">

      </div>
        </van-tab>
        <van-tab title="预约"></van-tab>
        <van-tab title="接机"></van-tab>
      </van-tabs>
      
    </div>
  </div>
</template>
<script>
import TopBar from '@/components/Nav.vue'
// import Vue from 'vue'
// import { Tab, Tabs } from 'vant'

// Vue.use(Tab)
// Vue.use(Tabs)
export default {
  name: 'Home',
  components: {
    TopBar,
  },
  data() {
    return {
      map: null,
      polylineL: null,
      infoWindow: null,
      mapContent: '',
      zoom: '',
      markers: [],
      cluster: null,
      active:0
    }
  },
  mounted() {
    // this.map = new AMap.Map('container', {
    //   resizeEnable: true, //是否监控地图容器尺寸变化
    //   zoom: 15, //初始化地图层级
    //   center: [113.65, 34.76], //初始化地图中心点
    // })
    // this.zoom = this.map.getZoom() //获取当前地图级别
    // this.map.setMapStyle('amap://styles/whitesmoke') //自定义地图的样式
    this.initMap()
  },
  methods: {
    initMap() {
      var mapObj = new AMap.Map('container') //以为这里是要获取地图容器的id,所以要写在mounted之中
      var geolocation
      var that = this
      mapObj.plugin('AMap.Geolocation', function () {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: false, // 显示定位按钮，默认：true
          buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        })
        mapObj.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', that.onComplete) // 返回定位信息
        AMap.event.addListener(geolocation, 'error', that.onError) // 返回定位出错信息
      })
    },
    onComplete(obj) {
      this.positionInfo = obj
      var res =
        '经纬度：' +
        obj.position +
        '\n精度范围：' +
        obj.accuracy +
        '米\n定位结果的来源：' +
        obj.location_type +
        '\n状态信息：' +
        obj.info +
        '\n地址：' +
        obj.formattedAddress +
        '\n地址信息：' +
        JSON.stringify(obj.addressComponent, null, 4)
      console.log(this.positionInfo)
      console.log(res)
    },
    onError(obj) {
      alert(obj.info + '--' + obj.message)
      console.log(obj)
    },
  },
}
</script>
<style scoped  lang="scss">
.home{
  width: 100%;
  height: 100vh;
}
.topbar{
  
}
#container {
  width: 100%;
  height: 100vh;
}
.infoBox{
  position: fixed;
  bottom: 20px;
  width: 100%;
  .tabs{
    width: 90%;
    margin: auto;
    margin-bottom: 15px;

  }
  .locationInfo{
     width: 90%;
     height: 120px;
     border-radius: 10px;
     background-color: #FFFFFF;
    margin: auto;

  }
}
</style>
