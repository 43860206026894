<template>
  <div>
    <van-dialog v-model="show" title="标题" show-cancel-button @confirm="confirm" @cancel="cancel">
      <span style="margin-left:20px">点击确认会获取到你的用户信息是否确认</span> 
</van-dialog>
  </div>
</template>

<script>

export default {
   
  name: 'TaxiH5Popup',

  data() {
    return {
      show:true
    };
  },

  mounted() {
    
  },

  methods: {
    //确认
    confirm(){
      this.$router.push({
        path:'/home'
      })
    },
    //取消
    cancel(){
     this.$router.go(-1);//返回上一层
    }
  }
};
</script>

<style lang="scss" scoped>

</style>