<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="订单关闭" left-arrow @click-left="$router.back()" />
    </div>
     <div>
      <div style="margin-top:20px" class="locationInfo">
        <div class="statrt">
          <img style="width:10px;height:10px" src="../../assets/images/startAddress.png" alt />
          <input
            type="text"
            v-model="startAddress"
            placeholder="请输入上车地址"
            class="input"
           
          />
        </div>
        <van-divider />
        <div class="end">
          <img style="width:10px;height:10px" src="../../assets/images/endAddress.png" alt />
          <input
                  v-model="endAddress"
                 
                  type="text"
                  class="input"
                  placeholder="请输入下车地址"
          />
        </div>
      </div>
      <div style="margin-top:20px" class="locationInfo">
        <div style="text-align: center;padding-top:30px;color:#333;font-weight:bold;font-size:16px">订单已结束</div>
        
        <div style="text-align: center;padding-top:10px">  {{title}}</div>
      </div>
    </div>
    <div class="foot" @click="go">
      <button class="btn"> <img style="width:15px;height:15px" src="../../assets/images/index.png" alt=""> 返回首页</button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {pay_api} from '../../http/api/price'
export default {
  name: 'TaxiH5Over',

  data() {
    return {
        startAddress:'',
        endAddress:'',
        title:''
    }
  },

  mounted() {
      this.startAddress = sessionStorage.getItem('startAddress')
      this.endAddress = sessionStorage.getItem('endAddress')
      this.title = this.$route.query.title

      this.pay()
  },

  methods: {

      async pay() {
          //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
          let res = await pay_api({
              couponId: 0,
          })
          console.log(res)
          if (res.code == 200) {
              sessionStorage.removeItem('endAddress')
              sessionStorage.removeItem('endIp')
              sessionStorage.removeItem('nowOrder')
              sessionStorage.removeItem('startAddress')
              sessionStorage.removeItem('startIp')
          }
      },
    //返回首页
    go(){
      this.$router.push({
        path:'/index'
      })
    },
    onClickLeft() {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #eee;
  .locationInfo {
    
    height: 120px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    width: 90%;
   
    .statrt {
      height: 40px;
      line-height: 50px;
      margin-left: 20px;
        .input{
      overflow: hidden;
      text-overflow:ellipsis;
white-space:nowrap;
margin-left:10px;border:0;width:80%;
background-color:transparent;
    }
    }

    .end {
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
        .input{
      overflow: hidden;
      text-overflow:ellipsis;
white-space:nowrap;
margin-left:10px;border:0;width:80%;
background-color:transparent;
    }
    }
  }
  .foot{
    .btn{
      width: 90%;
      height: 50px;
      margin: 0 5%;
      background: #fff;
      border-radius: 15px;
      border:0;
      margin-top:40%;
    }
  }
}
</style>