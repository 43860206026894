<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="支付订单"
                   left-arrow
                   @click-left="$router.back()"></van-nav-bar>
    </div>
    <div class="topbox">
      <div>
        <div style="font-size:16pt;padding-top:20px">本订单您需支付</div>
        <van-divider />
        <div>
          <div>
            <span style="font-size:20pt">￥{{totalFee}}</span>
          </div>
        </div>
        <!--<div style="padding-top:10px">当前积分持有{{integral}}分，可使用积分抵扣{{mileage}}元</div>-->
      </div>
    </div>
    <div></div>
    <div class="address">
      <div class="input"
           style="height:35%;margin-left:20px;line-height: 50px;width:90%">
        <span class="yq">11</span>
        <span style="margin-left:30px;font-size:16px;width:90%">{{startAddress}}</span>
      </div>
      <van-divider />
      <div class="input"
           style="height:35%;margin-left:20px;width:90%">
        <span class="yellow">11</span>
        <span style="margin-left:30px;font-size:16px;width:90%">{{endAddress}}</span>
      </div>
    </div>
    <div class="coupons">
      <van-coupon-cell :coupons="coupons"
                       :chosen-coupon="chosenCoupon"
                       @click="show" />
      <!-- 优惠券列表 -->
      <van-popup v-model="showList"
                 round
                 position="bottom"
                 style="height: 90%; padding-top: 4px;">
        <van-coupon-list :coupons="coupons"
                         :chosen-coupon="chosenCoupon"
                         :disabled-coupons="disabledCoupons"
                         @change="onChange"
                         :show-exchange-bar="false"
                         :show-count="false" />
      </van-popup>
    </div>
    <button class="btn"
            v-if="!this.zs"
            @click="pay">付款</button>
    <button class="btn"
            v-if="this.zs"
            @click="wxPayment">微信付款</button>
  </div>
</template>

<script>
import { useCoupon_api, pay_api } from '../../http/api/price'
import { wxpay_api } from '../../http/api/pay'
import { Toast, Dialog } from 'vant'

export default {
  name: 'TaxiH5Payment',
  components: {},
  data () {
    return {
      zs: false,
      showList: false,
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      totalFee: '',
      nowOrder: {},
      couponId: 0,
      startAddress: '',
      endAddress: '',
      integral: 10060,
      mileage: 100,
      order: null,
      mic: true,
      openid: '',
      payParam: {}
    }
  },
  created () {

    this.openid = localStorage.getItem('openid')
    if (this.openid == null) {
      this.openid = ''
    }
  },
  mounted () {
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.mic = false
    }
    // if(JSON.parse(sessionStorage.getItem('nowOrder'))==null){
    //     this.$router.push({
    //         path:'/index'
    //     })
    // }
    // this.nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
    this.startAddress = sessionStorage.getItem('startAddress')
    this.endAddress = sessionStorage.getItem('endAddress')
    this.token = JSON.parse(sessionStorage.getItem('token')).token
    this.totalFee = JSON.parse(sessionStorage.getItem('nowOrder')).totalFee

    this.order = JSON.parse(sessionStorage.getItem('nowOrder'))
    if (this.order.ch == 1) {
      this.zs = true
    }
    this.cancel()
  },

  methods: {
    wxPayment () {
      //Toast('确定拨打电话：010-xxxxxx吗？');
      Dialog.confirm({
        title: '提示',
        message:
          '您已使用积分抵扣' +
          this.order.mileage +
          '元，另还需使用微信支付' +
          this.order.cash +
          '元',
        confirmButtonColor: '#09BB07',
      })
        .then(() => {
          this.wechatPayMethord()
        })
        .catch(() => {
          this.$router.push({
            path: '/index',
          })
        })
    },


    // async wechatPayMethord() {
    //     //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
    //     let res = await wxpay_api({
    //         orderNum:  this.order.orderNum,
    //         cash:  this.order.cash,
    //     })

    //     if (res.code == 200) {
    //         console.log(res)
    //         localStorage.setItem('orderNum', res.data.orderNum)
    //         // var timerID= setInterval(() => {
    //         //    this.orderstatus()
    //         //  }, 3000);
    //         //  console.log(timerID);
    //         let redirect_url='&redirect_url=https://mihuatang.xyz/Ok_rech'
    //         console.log(redirect_url);
    //         location.href=res.data.url+redirect_url
    //     }
    // },
    //微信支付
    async wechatPayMethord () {
      //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      let res = await wxpay_api({
        orderNum: this.order.orderNum,
        cash: this.order.cash,
        openId: this.openid,
      })

      if (res.code == 200) {
        this.payParam = res.data
        // alert(res.data.toString)
        localStorage.setItem('orderNum', res.data.orderNum)
        // var timerID= setInterval(() => {
        //    this.orderstatus()
        //  }, 3000);
        //  console.log(timerID);
        if (this.mic) {
          //外部浏览器支付
          let redirect_url = '&redirect_url=https://mihuatang.xyz/Ok_rech'
          console.log(redirect_url)
          location.href = res.data.url + redirect_url
        } else {
          this.wxpay(res.data, function (payResult) {
            console.log(payResult)
          })
        }
        setTimeout(() => {
          this.$router.push({
            path: '/Ok_rech',
          })
        }, 2000)
      }
    },
    async payMethord () {
      //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      let res = await pay_api({
        couponId: this.couponId,
      })
      console.log(res)
      if (res.code == 200) {
        //需进行微信支付
        if (res.data.status == 6) {
          this.order = res.data
          this.wxPayment()
        } else {
          Toast(res.message)
          var user = JSON.parse(sessionStorage.getItem('token'))
          user.integral = res.data.integralNew
          sessionStorage.setItem('token', JSON.stringify(user))
          sessionStorage.removeItem('endAddress')
          sessionStorage.removeItem('endIp')
          sessionStorage.removeItem('nowOrder')
          sessionStorage.removeItem('startAddress')
          sessionStorage.removeItem('startIp')
          setTimeout(() => {
            this.$router.push({
              path: '/index',
            })
            console.log('index')
          }, 1000)
        }
      }
    },
    //微信浏览器支付
    wxpay (params, callback) {
      if (typeof window.WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            this.onBridgeReady(params, callback),
            false
          )
        } else if (document.attachEvent) {
          document.attachEvent(
            'WeixinJSBridgeReady',
            this.onBridgeReady(params, callback)
          )
          document.attachEvent(
            'onWeixinJSBridgeReady',
            this.onBridgeReady(params, callback)
          )
        }
      } else {
        this.onBridgeReady(params, callback)
      }
    },
    onBridgeReady (params, callback) {
      console.log(params)
      console.log(callback)
      var that = this
      console.log(that)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: 'wx992b6dfbb02b02a1',
          timeStamp: this.payParam.timeStamp,
          nonceStr: this.payParam.nonceStr,
          package: this.payParam.package,
          signType: this.payParam.signType,
          paySign: this.payParam.sign,
        },
        function (res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            Toast({
              message: '支付成功',
              position: 'middle',
              duration: 3000,
            })

            //window.location.href = vm.BASE_URL + 'index.html#/depositResult'
          } else {
            Toast({
              message: '支付失败',
              position: 'middle',
              duration: 3000,
            })
          }

          //callback(res)
        }
      )
    },
    async cancel () {
      //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      let res = await useCoupon_api({
        totalFee: this.totalFee, //订单金额
      })

      if (res.code == 200) {
        this.coupons = res.data.use
        this.disabledCoupons = res.data.unusable
        console.log(res)
      }
    },

    show () {
      this.showList = true
    },
    onChange (index) {
      console.log('选择了优惠券')
      if (index >= 0) {
        console.log(this.coupons[index])
        //this.totalFee=this.nowOrder.totalFee
        this.totalFee = this.totalFee - this.coupons[index].value / 100
        this.couponId = this.coupons[index].id
        console.log(this.couponId)
      }

      this.showList = false
      this.chosenCoupon = index
    },
    pay () {
      this.payMethord()
      console.log('进入了')
    },
  },
  //      beforeDestroy() {
  //   clearInterval(this.timer);
  // }
}
</script>

<style lang="scss" scoped>
.bigbox {
  background: #f8f8f8;
  width: 100%;
  height: 100vh;
  .topbox {
    margin-top: 10px;
    height: 180px;
    background: #fff;
    width: 95%;
    margin-left: 2.5%;
    text-align: center;
    border-radius: 15px;
  }
  .address {
    width: 95%;
    height: 100px;
    margin-top: 10px;
    background: #fff;
    margin-left: 2.5%;
    border-radius: 15px;
    .input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .yq {
      width: 20px;
      height: 20px;
      background: #62ce62;
      border-radius: 50%;
      color: #62ce62;
    }
    .yellow {
      width: 20px;
      height: 20px;
      background: #ffb540;
      border-radius: 50%;
      color: #ffb540;
    }
  }
  .coupons {
    width: 95%;
    height: 40px;
    border-radius: 15px;
    margin: 10px 2.5%;
  }
  .btn {
    width: 95%;
    height: 40px;
    margin: 20% 2.5%;
    background: #eb4541;
    border: 0;
    color: #fff;
    border-radius: 15px;
  }
}
</style>