<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="取消行程" left-arrow @click-left="$router.back()" />
    </div>
    <div class="main">
      <div class="box">
        <img style="width:70px;height:70px" src="../../assets/images/cancelOrder.png" alt />
        <div style="color:#333333;font-size:14px;">您正在取消订单</div>
        <div style="color:#666;font-size:12px">2分钟内取消行程，不需要付费补偿司机</div>
      </div>
      <div class="btnbox">
        <button class="btn" @click="$router.back()">再等等吧</button>
        <button class="btn2" @click="over">取消行程</button>
      </div>
    </div>
  </div>
</template>

<script>
import { no_api } from '../../http/api/price'
import { Toast } from 'vant'
export default {
  name: 'TaxiH5Cancel',

  data() {
    return {
      token:''
    }
  },

  mounted() {},

  methods: {
    //取消
    async over() {
        this.token = JSON.parse(sessionStorage.getItem('token')).token
      let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      console.log(nowOrder)
      let res = await no_api({
        pathOrderId: nowOrder.backNum, //易启返回订单号
        cancelReason: '不想坐了', //取消原因
        carSource: nowOrder.carType, //车型对应id
        token: this.token, //登录标识token
      })
      console.log(res)
      if (res.code == 200) {
         sessionStorage.removeItem('endAddress')
                        sessionStorage.removeItem('endIp')
                        sessionStorage.removeItem('nowOrder')
                        sessionStorage.removeItem('startAddress')
                        sessionStorage.removeItem('startIp')
        this.$router.push({
          path: '/home',
        })
      }
    },
    onClickLeft() {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;

  .main {
    display: flex;
     margin-top: 20%;
     flex-direction: column;
     justify-content: center;
    .box {
      text-align: center;
      line-height: 30px;
    
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-around;
    margin-top: 40%;
    .btn {
      width: 120px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      border: solid 1px #666666;
      background: #fff;
    }
    .btn2 {
      width: 120px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      border: solid 1px #eb4541;
      background: #fff;
      color: #eb4541;
    }
  }
}
</style>