<template>
  <div class="bigbox "
       id="targetbox">
    <!-- <div>
      <TopBar />
    </div> -->
    <div class="nav">
      <van-nav-bar left-arrow
                   @click-left="$router.back()" />
    </div>
    <div id="map">
      <div id="container"></div>
      <div id="panel"></div>
    </div>
    <div class="box"
         ref="drawer"
         :style="heightStyle"
         @scroll.passive="onListScrolled($event)">
      <div class="absobox">
        <div class="VerticalDrawable">
          <div class="content"
               v-for="(v, i) in listarr"
               :key="i">
            <div class="flex">
              <div>
                <div class="parent">
                  <img :src="v.carSourceLogo"
                       alt=""
                       class="child">
                </div>
                {{ v.carSourceName }}
              </div>
              <div class="size">预估 <span style="font-size:16px;font-weight:bold;color: #333;">{{
                                v.estimatePrice
                            }}</span>元
                <van-checkbox @change="change(v)"
                              v-model="v.checked"
                              shape="square"
                              class="check"
                              icon-size="14px"></van-checkbox>
                <div style="margin-top:5px">
                  <span v-if="v.time != null"
                        class="time">限时优惠</span>
                  <span style="color:#f60;margin-left:2px">{{ v.time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <button class="confirmBtn"
                @click="gohome">确认</button>
        <!-- <div class="check"><van-checkbox v-model="checked"
                        icon-size="16px"
                        @change="checkbtn">请确认勾选 <span @click="toright"
                  style="color:#f60">《用户注册协议》</span></van-checkbox></div> -->
      </div>
    </div>
  </div>
</template>
<script>
//import TopBar from '@/components/TopBar.vue'
import { price_api, order_api } from '../../http/api/price'
import Vue from 'vue';
import { Loading } from 'vant';
import { Toast } from 'vant'
Vue.use(Loading);
export default {
  name: 'TaxiH5Price',
  components: {
    // TopBar,
  },
  data () {
    return {
      show: true,
      checked: '',
      loading: true,
      listarr: [],
      map: null,
      active: 0,
      estimatePrice: '',
      distance: '',
      estimatedTime: '',
      CarSource: '4',
      carConstance: null,
      result: {},
      startip: null,
      endIp: null,
      startAddress: null,
      endAddress: null,
      value: '',//
      token: '',
      departureTime: '',
      phone: '',
      serviceType: '',
      cityID: '',
      city: '',
      list: [],
      isToped: true, //判断列表的第一个元素是否位于顶部
      isScollToTop: true, //true 向顶部滑动
      defaultHeight: 40, //记录默认高度 %
      currentHeight: 40, //记录当前窗体高度%
      window: {
        startHeight: 40,
        endHeight: 95,
      },
      startPos: {
        y: 0,
      },
      endPos: {
        y: 0,
      },
    }
  },
  computed: {
    heightStyle () {
      return {
        height: this.currentHeight + '%',
      }
    },
  },

  created () {
  },
  mounted () {
    this.handleTounchListener()
    this.token = JSON.parse(sessionStorage.getItem('token')).token
    this.startip = sessionStorage.getItem('startIp').split(',')
    this.endIp = sessionStorage.getItem('endIp').split(',')
    this.startAddress = sessionStorage.getItem('startAddress')
    this.endAddress = sessionStorage.getItem('endAddress')
    this.serviceType = sessionStorage.getItem('serviceType')
    console.log(this.serviceType, 'serviceType');
    //  this.cityID = sessionStorage.getItem('cityID')
    this.city = sessionStorage.getItem('city')
    this.cityID = 32
    if (this.serviceType != null && this.serviceType == 0) {
      this.serviceType = 1
    } else if (this.serviceType != null && this.serviceType == 2) {
      this.serviceType = 3
    } else {
      this.serviceType = 2
    }
    var departureTime = sessionStorage.getItem('departureTime')
    if (departureTime != null) {
      this.departureTime = departureTime;
    }
    this.phone = JSON.parse(sessionStorage.getItem('token')).phone
    this.initMap()
    this.getprice()
  },
  methods: {
    checkbtn (e) {
      console.log(e);
      this.check = e
    },
    toright () {
      this.$router.push({
        path: '/rigister',
      })
    },
    openDrawable () {
      // 60Hz, 16.6ms
      let id = setInterval(() => {
        if (this.isScollToTop) {
          this.isScollToTop = false
          this.currentHeight = this.window.endHeight
          clearInterval(id)
        } else {
          this.currentHeight = this.window.startHeight
          this.isScollToTop = true
          clearInterval(id)
        }
      }, 10)
    },
    onListScrolled (event) {
      let top = event.target.scrollTop
      this.isToped = top === 0
    },
    handleTounchListener () {
      this.$refs.drawer.addEventListener('touchstart', (event) => {
        //记录点击坐标
        this.startPos.y = event.targetTouches[0].pageY
        this.defaultHeight = this.currentHeight
      })
      this.$refs.drawer.addEventListener('touchmove', (event) => {
        //计算移动距离
        if (event.targetTouches.length > 1) {
          return
        }
        let touch = event.targetTouches[0]
        let dy = ((this.startPos.y - touch.pageY) * 100) / 667
        if (this.isToped && !this.isScollToTop && dy < 0) {
          //滑动区域
          this.endPos.y = touch.pageY
          this.currentHeight = Math.max(
            this.window.startHeight,
            this.defaultHeight + dy
          )
          event.preventDefault()
        } else if (this.isToped && dy > 0 && this.isScollToTop) {
          //向上
          event.preventDefault()
          this.endPos.y = touch.pageY
          this.currentHeight = Math.min(
            this.window.endHeight,
            this.defaultHeight + dy
          )
        }
      })
      this.$refs.drawer.addEventListener('touchend', (event) => {
        console.log('====touchend======', event)
        // 阈值 = 差值的20%
        const threshold =
          Math.abs(this.defaultHeight - this.currentHeight) * 0.2
        if (!this.isToped || threshold === 0) {
          return
        }
        const scrollDy = Math.abs(this.endPos.y - this.startPos.y)
        const isDirectorTop = this.endPos.y - this.startPos.y < 0
        const isToTop =
          (isDirectorTop && scrollDy > threshold) ||
          (!isDirectorTop && scrollDy < threshold)
        // 60Hz, 16.6ms
        let id = setInterval(() => {
          if (isToTop) {
            this.isScollToTop = false
            this.currentHeight = this.window.endHeight
            clearInterval(id)
          } else {
            this.currentHeight = this.window.startHeight
            this.isScollToTop = true
            clearInterval(id)
          }
        }, 10)
      })
      this.$refs.drawer.addEventListener('touchcancel', (event) => {
        console.log('=====touchcancel=====', event)
      })
    },
    onClickMore () {
      this.$emit('onClickMore')
    },

    change (e) {
      if (e.checked == true) {
        this.list.push(e)
      } else {
        let newlist = []
        this.list.forEach((v) => {
          if (v.checked == true) {
            newlist.push(v)
          }
        })
        this.list = newlist
      }
    },
    //地图
    initMap () {
      let start = sessionStorage.getItem('startIp').split(',')
      let end = sessionStorage.getItem('endIp').split(',')
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 11,
        center: sessionStorage.getItem('startIp').split(','),
      })
      //var position = [JSON.parse(start[0]), JSON.parse(start[1])];
      //信息窗口
      //起点
      let marker = new AMap.Marker({   // 标记点
        position: new AMap.LngLat(start[0], start[1]),
        content: '<div id="circle_route_bot" class="circle_route_bot" style="background:#fff;    line-height: 20px;">' + this.startAddress + ' </div>',
        offset: new AMap.Pixel(-5, -7)     // 偏移位置
      });
      //终点
      let markerUpon = new AMap.Marker({    // 信息窗体
        position: new AMap.LngLat(end[0], end[1]),
        content: '<div class="route_ticket" style="background:#fff; line-height: 20px;">' + this.endAddress + ' </div>',    // 自定义信息窗体内容
        offset: new AMap.Pixel(-60, -50)    // 偏移位置
      });
      let markers = [marker, markerUpon];
      this.map.add(markers);
      //构造路线导航类
      let driving = new AMap.Driving({
        map: this.map,
        //panel: "panel"
      })
      // function setAnchor(){
      //     var anchor = this.id;
      //     infoWindow.setAnchor(anchor)
      // }
      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        new AMap.LngLat(start[0], start[1]),
        new AMap.LngLat(end[0], end[1]),
        function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          console.log(result)
          if (status === 'complete') {
            console.log('获取驾车数据成功')
          } else {
            console.log('获取驾车数据失败')
          }
        }
      )
    },
    //预估车费
    async getprice () {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
      console.log(toast);
      //Flat=104.066581&Flng=30.590701&Tlat=104.055701&Tlng=30.586817&RequireLevel=1&CityId=32&ServiceType=1&PassengerPhone=13678085408&CarSource=1,3,4,5,7,11&FromAddress=四川省成都市武侯区茂业中心B座天府大道北段28号&ToAddress=四川省成都市武侯区天府新谷(府城大道西段)府城大道西段399号
      let res = await price_api({
        Flng: this.startip[1],
        Flat: this.startip[0],
        Tlng: this.endIp[1],
        Tlat: this.endIp[0],
        CityId: this.city,
        //City: this.city,
        RequireLevel: '1',
        ServiceType: this.serviceType,
        DepartureTime: this.departureTime,
        PassengerPhone: this.phone,
        // CarSource: this.CarSource,
        FromAddress: this.startAddress,
        ToAddress: this.endAddress,
      })
      console.log(res);
      if (res.code == 200) {
        this.listarr = res.data
        Toast.clear()//清除加载效果
        this.listarr.forEach((v) => {
          if (v.checked == true) {
            this.list.push(v)
          }
        })
      }
    },
    //确认打车
    async gohome () {
      let res = await order_api({
        serviceType: this.serviceType, //订单类型  1.实时单 2.预约订单 3.接机单 4.送机单 5.日租 6.半日租
        cityId: this.cityID, //城市id
        flat: this.startip[1], //出发地纬度
        flng: this.startip[0], //出发地经度
        startName: this.startAddress, //出发地名称
        startAddress: this.startAddress, //出发地详细地址
        tlat: this.endIp[1], //目的地纬度
        tlng: this.endIp[0], //目的地经度
        endName: this.endAddress, //目的地名称
        endAddress: this.endAddress, //目的地详细地址
        departureTime: this.departureTime, //出发时间(yyyy-MM-dd HH:mm:ss)
        requireLevel: '1', //车型
        dynamicCode: this.result.dynamicCode, //预估价动态码(调用预估价接口时，如果接口返回了DynamicCode则此处要传入，否则可以传空""或不传)
        estimatePrice: this.result.estimatePrice, //预估价
        carSource: this.CarSource, //平台  例如:曹操之类的对应id
        fltTakeoffTime: '', //航班起飞时间，如果是接机订单，则是必须的(yyyy-MM-dd HH:mm:ss)
        flightDelayTime: '', //航班到达后多少分钟出发，只能取10-90之间，10的整数倍数字 ，与AirCode成对出现，要么都传，要么都不传，如果是接机订单，则是必须的
        departureAirPortCode: '', //出发机场三字码，如果是接机订单，则是必须的
        arriveAirPortCode: '', //接机机场三字码，如果是接机订单，则是必须的
        token: this.token, //用户登录标识token
        cTransportList: this.list
      })
      if (res.code == 200) {
        sessionStorage.setItem("nowOrder", JSON.stringify(res.data))
        this.$router.push({
          path: '/car'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#targetbox {
  width: 100%;
  height: 100vh;
}

.circle_route_bot {
  background: #eb4541;
  font-size: 24px;
}

/deep/.amap-marker-content {
  height: 100px;
  color: #000;
  width: 200px;
}

.bigbox {
  width: 100%;
  height: 100vh;
  .parent {
    display: inline-block;
    width: 20px;
    height: 20px;

    .child {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  #map {
    background: #eee;
    width: 100%;
    height: 100%;
  }
  #container {
    width: 100%;
    height: 100%;
  }

  .box {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    //  overflow-y: scroll;
    background: #fff;
    height: 100px;
    .content {
      padding: 15px;
      overflow-y: scroll;
      background: #fff;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      margin: 0 5px;

      .size {
        font-size: 12px;
        color: #666;
      }

      .check {
        display: inline-block;
      }

      .time {
        background: #f60;
        color: #fff;
        padding: 1px;
        font-size: 12px;
      }

      .card {
        width: 30%;
        background: #eee;
        height: 60px;
        border-radius: 10px;
        text-align: center;
        line-height: 27px;
        margin-top: 10px;
      }

      .card:hover.card {
        background: #e5f0ff;
        height: 60px;
        color: #bdceed;
      }
    }
    .absobox {
      //         position:absolute;
      //   left: 0px;
      //   right: 0px;
      //   bottom: 0px;
      // //  overflow-y: scroll;
      //   background: #fff;
      //   height: 100px;

      .VerticalDrawable {
        //          position:absolute;
        //   left: 0px;
        //   right: 0px;
        //   bottom: 0px;
        overflow-y: scroll;
        //   background: #fff;
        height: 80%;
      }
    }
    /deep/.van-popup {
      position: static !important;
    }
  }
  .foot {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 80px;
    background: #fff;
    padding-top: 10px;

    box-shadow: 4px 4px 15px rgb(113, 105, 105);
    .confirmBtn {
      width: 90%;
      height: 40px;
      background: #eb4541;
      line-height: 70px;
      color: #ffffff;
      text-align: center;
      border-radius: 15px;
      margin: 0 5%;
      line-height: 40px;
      font-size: 14px;
      border: 0;
    }
    .check {
      margin-left: 25%;
      margin-top: 10px;
    }
  }
  /deep/.van-tabs__nav--card .van-tab.van-tab--active {
    color: #fff;
    background-color: #ccc;
  }
}
</style>