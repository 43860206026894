import axios from '../axios'
//
// 用户充值
export const wechat_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/recharge/addMoney", // 请求地址
        data: data,
    });
};
//查询是否支付成功
export const loadstatus_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/recharge/loadPayStatus", // 请求地址
        params: data,
    });
};
//用户充值记录
export const order_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/recharge/loadRechargeLog", // 请求地址
        params: data,
    });
};
//打车微信支付
export const wxpay_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/wx/pay", // 请求地址
        data: data,
    });
};
