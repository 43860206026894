<template>
  <div class="bigbox">
    <div class="search">
      <div style="padding-left:10px">{{this.city}}</div>
      <input v-if="isshow"
             v-model="go"
             type="text"
             @input="auto"
             placeholder="请输入您的上车点" />
      <input v-model="go"
             type="text"
             @input="auto"
             placeholder="请输入地点"
             :disabled="disb" />
      <!-- <a href="/home" style="padding-right:10px;color:#999"></a> -->
      <span @click="goback"
            style="padding-right:10px;color:#999">取消</span>
    </div>
    <!-- <div class="barbox" v-show="!ishow">
            <Bar/>
    </div>-->
    <div style="height:50px"
         v-show="ishow">
      <div class="topcar"
           @click="map"
           v-if="!disb">
        <img style="width:15px;height:20px"
             src="../../assets/images/selectAddress.png"
             alt />
        <span style="font-size:16px;color:#999;margin-left:20px">在地图上选址</span>
      </div>
      <van-divider />
      <div v-for="(v,i) in fillist"
           :key="i">
        <div class="mapbox"
             style="margin-top:10px;box-sizing: border-box;"
             @click="gomap(v.district+v.name+v.address,v.location)">
          <img style="width:15px;height:20px;margin-top:5px"
               src="../../assets/images/address1.png"
               alt />
          <div style="margin-left:20px; line-height:20px ;">
            <div style="font-size:16px">{{v.name}}</div>
            <div style="font-size:14px;color:#999">{{v.address}}</div>
          </div>
        </div>
        <van-divider />
      </div>
    </div>
    <!-- <div class="searh">
      <div>搜索记录</div>
      <div v-for="(v,i) in historyList" :key="i">
        <div class="title">{{v}}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
//import Bar from '@/components/Bar.vue'
export default {
  components: {
    //  Bar
  },
  name: 'TaxiH5Address',

  data () {
    return {
      isshow: false,
      ishow: true,
      go: '',
      active: '',
      disb: false,
      fillist: [
        // { name: '成都', address: '成都市双流区' },
        // { name: '成都', address: '成都市双流区' },
        // { name: '成都', address: '成都市双流区' },
        // { name: '成都', address: '成都市双流区' },
      ],
      geocodes: [],
      city: null,
      historyList: [], //历史搜索记录，存本地
    }
  },
  // created() {
  //   let arr=JSON.parse(localStorage.getItem('historyList')) 
  //   if(arr){
  //     this.historyList=arr
  //   }
  // },
  mounted () {
    this.city = sessionStorage.getItem('city')
    this.active = this.$route.query.active
    let e = this.$route.query.type
    console.log(e);
    if (e == 5) {
      this.go = '机场'
      this.disb = true
      this.auto()

    }
  },

  methods: {
    goback () {
      this.$router.back()
    },
    //地图选址
    map () {
      let type = this.$route.query.type
      let active = this.$route.query.active
      this.$router.push({ path: '/map', query: { type: type, active: active } })
    },
    //选择城市
    show () {
      console.log(11)
      this.ishow = false
    },
    getmap () {
      this.$http({
        method: 'get',
        url: 'https://restapi.amap.com/v3/assistant/inputtips',
        params: {
          key: '8a29494c53ffc607593add17513e1756',
          keywords: this.go,
          city: this.city,
        },
      })
        .then((res) => {
          console.log(res)
          var fillist = []
          res.data.tips.forEach((v) => {
            console.log('当前id', v.id[0])
            if (v.address[0] != null && v.district.indexOf(this.city) != -1) {
              fillist.push(v)
            }
          })
          console.log(this.disb);
          if (this.disb == true) {
            let jcname = []
            fillist.forEach((v) => {
              if (v.name.indexOf("天府") >= 0 || v.name.indexOf("双流") >= 0) {
                jcname.push(v)
                console.log();
              }

            })
            // let removedArr = fillist.filter((x) => x !== "新津机场");
            this.fillist = jcname
          } else {
            this.fillist = fillist
          }

        })
        .catch((err) => {
          console.log(err)
        })
    },
    //搜索地址
    auto (e) {
      if (this.go != '机场') {
        this.go = e.target.value
        this.getmap()
      } else {
        this.getmap()
      }

    },
    //选择地址
    gomap (name, l) {
      let location = l.split(',')
      console.log(name)
      let type = this.$route.query.type
      if (type == 1) {
        sessionStorage.setItem('startAddress', name)
        sessionStorage.setItem('startIp', [location[0], location[1]])
        this.$router.push({ path: '/home', query: { active: this.active } })
        //  this.$router.push({path: '/home', query: {type: 1}})
      } else if (type == 2) {
        sessionStorage.setItem('endAddress', name)
        sessionStorage.setItem('endIp', [location[0], location[1]])
        // this.historyList.unshift(name)
        // console.log(this.historyList)
        // localStorage.setItem('historyList', JSON.stringify(this.historyList))
        this.$router.push({ path: '/select' })
      } else if (type == 4) {
        sessionStorage.setItem('startAddress', name)
        sessionStorage.setItem('startIp', [location[0], location[1]])
        this.$router.push({ path: '/home2', query: { active: this.active } })
      } else if (type == 5) {
        sessionStorage.setItem('endAddress', name)
        sessionStorage.setItem('endIp', [location[0], location[1]])
        this.$router.push({ path: '/select' })
      } else if (type == 6) {
        sessionStorage.setItem('endAddress', name)
        sessionStorage.setItem('endIp', [location[0], location[1]])
        this.$router.push({ path: '/select' })
      }
      // this.$http({
      //     method: 'get',s
      //     url: 'https://restapi.amap.com/v3/geocode/geo',
      //     params: {
      //         key: '8a29494c53ffc607593add17513e1756',
      //         address: name,
      //         //TODO 替换当前城市
      //         city: this.city!=null?this.city:'成都'
      //     },
      // })
      //     .then((res) => {
      //         if (res.status == 200) {
      //             console.log(res)
      //             console.log(res.status)
      //             console.log(res.data.geocodes)
      //             let geocodes = res.data.geocodes
      //             let location = geocodes[0].location.split(",")
      //             console.log(location)
      //             let type = this.$route.query.type
      //             if(type==1){
      //                 sessionStorage.setItem("startAddress",name)
      //                 sessionStorage.setItem("startIp",[location[0],location[1]])
      //                 this.$router.push({ path: '/home'})
      //             }else if(type==2){
      //                 sessionStorage.setItem("endAddress",name)
      //                 sessionStorage.setItem("endIp",[location[0],location[1]])
      //                 this.$router.push({ path: '/price'})
      //             }

      //         }
      //     })
      //     .catch((err) => {
      //         console.log(err)
      //     })
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  .search {
    display: flex;
    color: #999;
    width: 100%;
    height: 8%;
    align-items: center;
    justify-content: space-between;

    box-shadow: 3px 3px 4px #eee;
    img {
      width: 10px;
      height: 10px;
    }
    input {
      border-radius: 15px;
      background: #f5f5f5;
      border: 0;
      height: 30px;
      width: 60%;
      color: #999;
      padding-left: 10px;
      margin-left: 20px;
    }
  }
  .barbox {
    background: #f2f2f2;
    height: 92%;
  }
  .mapbox {
    display: flex;
    margin-left: 20px;
    box-sizing: border-box;
  }
  .topcar {
    margin-left: 20px;
    height: 40px;
    line-height: 60px;
  }
  .searh {
    .title {
      color: #999;
      height: 40px;
      margin-left: 20px;
    }
  }
}
</style>