<template>
  <div class="bigbox">
    <div>
      <Nav2 />
    </div>
    <div id="container"></div>
    <div class="info">
      <span v-text="message"></span>
    </div>
    <div class="infoBox">
      <div class="address">{{this.ipt}}</div>
      <div class="confirmBtn"
           v-if="this.ipt!=''&&this.ipt!=null"
           @click="gohome">确认</div>
      <div class="confirmBtn"
           v-else
           style="background:#eee;color:#666">确认</div>
    </div>
  </div>
</template>
<script>
import Nav2 from '@/components/Nav2.vue'
export default {
  components: {
    Nav2
  },
  name: 'TaxiH5Map',

  data () {
    return {
      location: '',
      ipt: '',
      //实时屏幕高度
      windowHeight: document.documentElement.clientHeight,
      map: null,
      center: [116.311877, 39.991701],
      message: ''
    }
  },

  mounted () {
    // 当浏览器被重置大小时执行
    window.onresize = () => {
      return (() => {
        this.windowHeight = document.documentElement.clientHeight
      })()
    }
    let type = this.$route.query.type

    this.ipt = sessionStorage.getItem("startAddress")
    if (type == 2 || type == 6 && sessionStorage.getItem("endAddress") != null) {
      this.ipt = sessionStorage.getItem("endAddress")
    }


    this.map = new AMap.Map('container', {
      resizeEnable: true,
      zoom: 15,
      center: this.center,

    })
    this.map.on('click', function (e) {
      console.log('您在 [ ' +
        e.lnglat.getLng() +
        ',' +
        e.lnglat.getLat() +
        ' ] 的位置双击了地图！');
    });
    //调用地图初始化方法
    this.initAMap()
    //this.clickOn()
  },

  methods: {

    newmap () {
      //        // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(113.397428, 23.2),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '默认图标'
      });
      // 将创建的点标记添加到已有的地图实例：
      let map
      map.add(marker);
    },

    gohome () {
      let type = this.$route.query.type
      let active = this.$route.query.active
      let ip = this.location.split(",")
      if (type == 1) {
        sessionStorage.setItem("startAddress", this.ipt)
        sessionStorage.setItem("startIp", [ip[0], ip[1]])
        // this.$router.push({ path: '/home'})
        this.$router.push({ path: '/home', query: { type: type, active: active } })
      } else if (type == 2) {
        sessionStorage.setItem("endAddress", this.ipt)
        sessionStorage.setItem("endIp", [ip[0], ip[1]])
        this.$router.push({ path: '/select' })
      } else if (type == 5 || type == 6) {
        sessionStorage.setItem("endAddress", this.ipt)
        sessionStorage.setItem("endIp", [ip[0], ip[1]])
        this.$router.push({ path: '/select' })
      }
      else if (type == 4) {
        sessionStorage.setItem("startAddress", this.ipt)
        sessionStorage.setItem("startIp", [ip[0], ip[1]])
        // this.$router.push({ path: '/home'})
        this.$router.push({ path: '/home2', query: { type: type, active: active } })
      }

    },
    initAMap () {
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: 16,
        center: sessionStorage.getItem("startIp").split(","),
      })
      this.map.on('click', this.showInfoClick)


      var start = sessionStorage.getItem("startIp").split(",");

      var marker = new AMap.Marker({
        position: new AMap.LngLat(start[0], start[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '默认图标'
      });
      // // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);

    },


    showInfoClick (e) {
      //添加前先移除
      // 获取已经添加的marker
      var markerold = this.map.getAllOverlays('marker');
      // 单独移除点标记
      this.map.remove(markerold);
      console.log([e.lnglat.getLng(), e.lnglat.getLat()])
      this.map.setCenter([e.lnglat.getLng(), e.lnglat.getLat()])
      var marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '默认图标'
      });
      // // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
      console.log([e.lnglat.getLng(), e.lnglat.getLat()])
      this.$http({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo',
        params: {
          key: '7a528f38aa043570d415be4dbdbbdf1c',
          location: e.lnglat.getLng() + "," + e.lnglat.getLat(),
        },
      })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.status)
            console.log(res)
            let address = res.data.regeocode.formatted_address

            let sub = address.substring(address.indexOf("市") + 1)
            console.log(sub)
            this.ipt = sub
            this.location = e.lnglat.getLng() + "," + e.lnglat.getLat()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }


  },

  getAddress (location) {
    console.log(location)
    this.$http({
      method: 'get',
      url: 'https://restapi.amap.com/v3/geocode/regeo',
      params: {
        key: 'd1a157dc3b050d0b6aeea42d3d9d9e54',
        location: location,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.status)
          console.log(res)

        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  #container {
    width: 100%;
    height: 100%;
    flex: 1;
    /* 溢出处理 */
    overflow-y: scroll;
  }
  .infoBox {
    z-index: 999999;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 30px 0;
    height: 150px;
    background: #ffffff;
    box-sizing: border-box;
    .address {
      width: 93%;
      height: 40px;
      background: #eeeeee;
      border-radius: 20px;
      margin: 0 auto;
      margin-bottom: 10px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 20px;
      box-sizing: border-box;
    }

    .confirmBtn {
      width: 93%;
      height: 40px;
      background: #eb4541;
      // line-height: 70px;
      color: #ffffff;
      text-align: center;
      border-radius: 15px;
      margin: auto;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
</style>